import React, { useState, useEffect, useRef } from 'react';
import './StatsSection.css';
import CustomColorText from '../CustomColorText';

const StatsSection = ({ yearsOfExperience, numberOfProjects, happyClients }) => {

  const [years, setYears] = useState(0);
  const [projects, setProjects] = useState(0);
  const [clients, setClients] = useState(0);
  const sectionRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Change this threshold value as needed
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const targetYears = yearsOfExperience;
          const targetProjects = numberOfProjects;
          const targetClients = happyClients;

          let yearsCounter = 0;
          let projectsCounter = 0;
          let clientsCounter = 0;

          const interval = setInterval(() => {
            if (yearsCounter <= targetYears) {
              setYears(yearsCounter);
              yearsCounter++;
            }

            if (projectsCounter <= targetProjects) {
              setProjects(projectsCounter);
              projectsCounter++;
            }

            if (clientsCounter <= targetClients) {
              setClients(clientsCounter);
              clientsCounter++;
            }

            if (yearsCounter > targetYears && projectsCounter > targetProjects && clientsCounter > targetClients) {
              clearInterval(interval);
            }
          }, 10); // Adjust the animation speed as needed (milliseconds)

          observer.unobserve(entry.target);
        }
      });
    }, options);

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, [yearsOfExperience, numberOfProjects, happyClients]);

  return (
    <section className="experience-section" ref={sectionRef}>
      <div className="item">
        <h3><CustomColorText color="#cc00c5">{years}+</CustomColorText></h3>
        <p>Years of Experience</p>
      </div>
      <div className="item">
        <h3><CustomColorText color="#cc00c5">{projects}+</CustomColorText></h3>
        <p>Number of Projects</p>
      </div>
      <div className="item">
        <h3><CustomColorText color="#cc00c5">{clients}+</CustomColorText></h3>
        <p>Happy Clients</p>
      </div>
    </section>
  );
};

export default StatsSection;
