import React, { useState } from 'react';
import './ContactSection.css';
import CustomColorText from "../CustomColorText";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const ContactSection = () => {

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {

    if (isSubmitting) {
      return;
    }
    try {
      setIsSubmitting(true);
      const response = await fetch('https://iam-faharid-2c324d0239f7.herokuapp.com/api/prd/users', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        setIsSubmitting(false);
        setIsSubmitted(true);
        resetForm();
      } else {
        console.error('API error:', response.status);
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    message: Yup.string().required('Message is required'),
  });

  return (
    <section className="contact-section">
      <h2>Leave your message or send your request to <CustomColorText color="#cc00c5"><a href="mailto:iam@faharid.com" target="_blank" rel="noreferrer">iam@faharid.com</a></CustomColorText></h2>
      <div>
        {!isSubmitted ? (
          <><Formik
            initialValues={{ name: '', email: '', message: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="input-container">
                <label htmlFor="name"><CustomColorText color="#cc00c5">*</CustomColorText> NAME</label>
                <Field
                  type="name"
                  id="name"
                  name="name"
                  placeholder="Your name" />
                <ErrorMessage name="name" component="div" className="error-message" />
              </div>
              <div className="input-container">
                <label htmlFor="email"><CustomColorText color="#cc00c5">*</CustomColorText> EMAIL</label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your email address" />
                <ErrorMessage name="email" component="div" className="error-message" />
              </div>
              <div className="input-container">
                <label htmlFor="message"><CustomColorText color="#cc00c5">*</CustomColorText> MESSAGE</label>
                <Field
                  as="textarea"
                  id="message"
                  name="message"
                  placeholder="Write your message here" />
                <ErrorMessage name="message" component="div" className="error-message" />
              </div>
              <button type="submit" className="submit-button" disabled={isSubmitting}>
                {isSubmitting ? (
                  <div className="spinner"></div>
                ) : (
                  'SEND MESSAGE'
                )}
              </button>
            </Form>
          </Formik><h3>OR</h3></>
        ) : (
          <p>Thanks for sending me your <CustomColorText color="#cc00c5">request</CustomColorText></p>
        )}
      </div>
      
      <button type="submit" className="submit-button">
        <a target="_blank" rel="noreferrer" href="https://calendly.com/iamfaharid/15min">
        SCHEDULE A MEETING
        </a>
      </button>
      
    </section>
  );
};

export default ContactSection;