import React from 'react';
import './Footer.css';
import { FaGithub, FaSlack, FaWhatsapp, FaLinkedinIn } from 'react-icons/fa'; // Import the social media icons from 'react-icons/fa'


const Footer = () => {
  const iconSize = 16;

  return (
    <footer className="footer">
      <div className="social-icons">
        <a href="https://github.com/faharid" target="_blank" rel="noopener noreferrer">
          <FaGithub size={iconSize}/>
        </a>
        <a href="https://faharid.slack.com/team/U05LDAFPKJR" target="_blank" rel="noopener noreferrer">
          <FaSlack size={iconSize}/>
        </a>
        <a href="https://api.whatsapp.com/send?phone=13212182411&text=Hey, I'm interested in hiring a developer, are you available?" target="_blank" rel="noopener noreferrer">
          <FaWhatsapp size={iconSize}/>
        </a>
        <a href="https://www.linkedin.com/in/faharid/" target="_blank" rel="noopener noreferrer">
          <FaLinkedinIn size={iconSize}/>
        </a>
      </div>
      <div className="privacy-policy">
       {/*  <a href="/privacy-policy">Privacy Policy</a> |{' '}
        <a href="/disclaimer">Disclaimer</a> */}
      </div>
    </footer>
  );
};

export default Footer;
