import React from 'react';
import "./CertificateElement.css";

const CertificateElement = ({ certificate }) => {
  const cardStyle = {
    backgroundImage: `url(${certificate.imageUrl})`,
  };

  return (
    <div className="certificate-element">
      <a href={certificate.link} target="_blank" rel="noopener noreferrer">
        <div className="certificate-card" style={cardStyle}>
          <div className="certificate-content">
          </div>
        </div>
      </a>
      <h3>{certificate.title}</h3>
    </div>
  );
};

export default CertificateElement;
