/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from "react";
import "./CertificatesSection.css";
import CertificateElement from "./CertificateElement";
import { Container, Row, Col } from "reactstrap";

const CertificatesSection = () => {

  const certificates = [
    {
      id: 1,
      title: "Networking and Communications Electronic Engeniering Bachelor's Degree",
      imageUrl: '/assets/cert1.png',
      link: '../assets/cert1.png',
      category: 'All',
    },
    {
      id: 2,
      title: 'Angular 14 Pro',
      imageUrl: '/assets/cert2.png',
      link: 'https://cursos-dev.com/certificate/nVzjn5PpPG',
      category: 'Frontend',
    },
    {
      id: 3,
      title: 'AWS Serverless for Developers',
      imageUrl: '/assets/cert3.png',
      link: 'https://cursos-dev.com/certificate/IOEWYVLqv6',
      category: 'Backend',
    },
    {
      id: 11,
      title: 'Microservices with Node, Docker y Kubernetes',
      imageUrl: '/assets/cert11.png',
      link: 'https://cursos-dev.com/certificate/UbgTexvdEs',
      category: 'Backend',
    },
    {
      id: 4,
      title: 'SAP Certified Development Associate ABAP',
      imageUrl: '/assets/cert4.png',
      link: '../assets/cert4.png',
      category: 'SAP',
    },
    {
      id: 5,
      title: 'ABAP Developer',
      imageUrl: '/assets/cert5.png',
      link: '../assets/cert5.png',
      category: 'SAP',
    },
    {
      id: 6,
      title: 'RISE with SAP S/4HANA Cloud',
      imageUrl: '/assets/cert6.png',
      link: '../assets/cert6.png',
      category: 'SAP',
    },
    {
      id: 7,
      title: 'CCNA Exploration: Network Fundamentals',
      imageUrl: '/assets/cert7.png',
      link: '../assets/cert7.png',
      category: 'Networking',
    },
    {
      id: 8,
      title: 'Build Your Own SAP Fiori App in the Cloud',
      imageUrl: '/assets/cert8.png',
      link: 'https://open.sap.com/verify/xugon-tomod-kizep-sotun-zatom',
      category: 'SAP',
    },
    {
      id: 9,
      title: 'Software Development on SAP HANA',
      imageUrl: '/assets/cert9.png',
      link: 'https://open.sap.com/verify/xuron-goraz-zatar-sasit-nizom',
      category: 'SAP',
    },
    {
      id: 10,
      title: 'Developing Java-Based Apps on SAP HANA Cloud Platform',
      imageUrl: '/assets/cert10.png',
      link: 'https://open.sap.com/verify/xosok-dacez-nysor-gedec-cidac',
      category: 'SAP',
    },
    {
      id: 12,
      title: 'Developing Web Apps with SAPUI5',
      imageUrl: '/assets/cert12.png',
      link: 'https://open.sap.com/verify/xoron-babuc-macih-dotum-lavoz',
      category: 'Frontend',
    },
    {
      id: 13,
      title: 'Imagine IoT',
      imageUrl: '/assets/cert13.png',
      link: 'https://open.sap.com/verify/xobid-tepel-kazog-fydez-lybir',
      category: 'Networking',
    },
    {
      id: 14,
      title: 'Implementation of SAP S/4HANA',
      imageUrl: '/assets/cert14.png',
      link: 'https://open.sap.com/verify/xedel-lidup-tohif-savog-digyg',
      category: 'SAP',
    },
    {
      id: 15,
      title: 'Big Data with SAP HANA Vora',
      imageUrl: '/assets/cert15.png',
      link: 'https://open.sap.com/verify/xohis-kydyl-cynes-gapes-vibuz',
      category: 'SAP',
    },
  ];

  const categorylist = [
    { id: 1, category: "All", value: "All" },
    { id: 2, category: "SAP", value: "All" },
    { id: 3, category: "Frontend", value: "All" },
    { id: 4, category: "Backend", value: "All" },
    { id: 5, category: "Networking", value: "All" },
  ];

  const [isActive, setIsActive] = useState(true);

  const [filter, setFilter] = useState('All');

  const filteredPortfolio = useMemo(() => {
    if (filter === "All") return certificates;

    return certificates.filter((portfolio) => portfolio.category === filter);
  }, [filter]);
    
  return (
    <section className="featured-certificates-section">
      <div className="certificate-elements">
        <Container>
          <Row className="mb-10">
            <Col md="6">
              <ul className="portafolio-row portfolio-nav">
                {categorylist.map((types, index) => (
                  <li key={index}>
                    <button className={`filter-button ${ isActive && index === 0 && 'active'}`} onClick={() => { setIsActive(false); setFilter(types.category)}}>
                      {types.category}
                    </button>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          <Row className="portafolio-row">
            {filteredPortfolio.map((certificate) => (
              <Col md="3" className="mb-4" key={certificate.id}>
                <CertificateElement key={certificate.id} certificate={certificate} />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default CertificatesSection;