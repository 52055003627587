import "./App.css";
import CustomColorText from "./components/CustomColorText";
import FloatingMenu from "./components/menu/FloatingMenu";
import StatsSection from "./components/stats/StatsSection";
import TimelineSection from "./components/timeline/TimelineSection";
import ContactSection from "./components/contact/ContactSection";
import CertificatesSection from "./components/certificates/CertificatesSection";
import SkillsSection from "./components/skills/SkillsSection"; 
import ProjectsSection from "./components/projects/ProjectsSection"; 
import Footer from "./components/footer/Footer";
import React, { useState, useEffect, useRef } from "react";
import NET from 'vanta/dist/vanta.net.min'

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [vantaEffect, setVantaEffect] = useState(null)
  const myRef = useRef(null)
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        NET({
          el: myRef.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.00,
          minWidth: 200.00,
          scale: 1.00,
          scaleMobile: 1.00,
          color: 0xcd00ff,
          backgroundColor: 0x0
        }))
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])

  return (
    <div className="App custom-scrollbar">
      <button
          onClick={toggleMenu}
          className="Menu-button text-white text-sm px-2 py-2 border rounded-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 9h16.5m-16.5 6.75h16.5"
            />
          </svg>
      </button>
      <FloatingMenu isOpen={isOpen} toggleMenu={toggleMenu} />
      <div ref={myRef}>
        <div className="App-section background-none" id="home">
          <div className="resume-section">
            <div className="resume-image-container">
              <img
                className="resume-image"
                src="/assets/profile.png"
                alt="This is me"
              />
            </div>
            <div className="resume-text">
              <div className="title fade-in-from-right">
                <h1>
                  Hi, I'm <CustomColorText color="#cc00c5">Faharid</CustomColorText>,
                  Software Developer and Entrepreneur
                </h1>
                <div className="subtitle fade-in-from-right">
                  <p>
                    <CustomColorText color="#ffffff">Dynamic developer and designer with a strong passion for innovation and a keen enthusiasm for adopting cutting-edge technologies.</CustomColorText>
                  </p>
                </div>
                <div className="subtitle download-text fade-in-from-right">
                  Download Resume
                  <a
                    href="./assets/Faharid_Manjarrez_2023_EN.pdf"
                    download="Faharid_Manjarrez_2023_EN.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    EN
                  </a>
                  <a
                    href="./assets/Faharid_Manjarrez_2023_ES.pdf"
                    download="Faharid_Manjarrez_2023_ES.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    ES
                  </a>
                </div>
                <div className='scrolldown'>
                  <div className="chevrons">
                    <div className='chevrondown'></div>
                    <div className='chevrondown'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="App-section" id="about">
        <div className="resume-section">
          <div className="resume-text">
            <div className="title">
              <h3>
                I embrace obstacles as opportunities for growth, that <CustomColorText color="#cc00c5">defines</CustomColorText> me
              </h3>
              <p>
                Throughout my career, I've had the opportunity to work on
                exciting projects, collaborate with talented teams, and
                continuously learn and grow in the ever-evolving world of
                technology.
              </p>
              <p>
                On this page, you'll find information about my <CustomColorText color="#cc00c5">skills</CustomColorText>, <CustomColorText color="#cc00c5">experience</CustomColorText>,
                and <CustomColorText color="#cc00c5">achievements</CustomColorText>. Feel free to explore my projects, and get a
                glimpse into my professional journey.
              </p>
            </div>
            <StatsSection yearsOfExperience={10} numberOfProjects={60} happyClients={30}/>
          </div>
        </div>
      </div>
      <div className="App-section" id="resume">
        <div className="resume-section">
          <div className="resume-text">
            <div className="experience">
              <h1>
                Education & <CustomColorText color="#cc00c5">Experience</CustomColorText>    
              </h1>
            </div>
            <TimelineSection />
          </div>
        </div>
      </div>
      <div className="App-section" id="skills">
        <div className="resume-section">
          <div className="resume-text">
            <div className="experience">
              <h1>
                My <CustomColorText color="#cc00c5">Specializations</CustomColorText>  and <CustomColorText color="#cc00c5">Skills</CustomColorText>    
              </h1>
              <SkillsSection />
            </div>
          </div>
        </div>
      </div>
      <div className="App-section" id="projects">
        <div className="resume-section">
          <div className="resume-text">
            <div className="experience">
              <h1>
                Featured <CustomColorText color="#cc00c5">Projects</CustomColorText>    
              </h1>
              <ProjectsSection />
            </div>
          </div>
        </div>
      </div>
      <div className="App-section" id="certificates">
        <div className="resume-section">
          <div className="resume-text">
            <div className="experience">
              <h1>
                My <CustomColorText color="#cc00c5">Certificates</CustomColorText> and <CustomColorText color="#cc00c5">courses</CustomColorText>    
              </h1>
              <CertificatesSection />
            </div>
          </div>
        </div>
      </div>
      <div className="App-section" id="contact">
        <div className="resume-section">
          <div className="resume-text">
            <div className="experience">
              <h1>
                Let's get in <CustomColorText color="#cc00c5">touch</CustomColorText>    
              </h1>
              <ContactSection />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
