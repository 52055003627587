import React from 'react';
import './SkillElement.css'; // Import your CSS file for the skill element

const SkillElement = ({ skillName, description, percentage }) => {
  return (
    <div className="skill-element">
      <h3>{skillName}</h3>
      <p>{description}</p>
    </div>
  );
};

export default SkillElement;
