/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "./SkillsSection.css"; // Import your CSS file for the Skills section
import SkillElement from "./SkillElement";
import CustomColorText from "../CustomColorText";

const SkillsSection = () => {

  const skills = [
    {
      id: 1,
      skillName: "SAP",
      description:
        "ERP consultant and developer, focused on successful implementation projects. Proficient in leveraging tools like Fiori and SAPUI5.",
      animation: "left",
      brief: "Throught my career as an ABAP developer I've worked in a multitude of industries and SAP Modules such as FI (Financial Accounting), SD (Sales and Distribution), MM (Materials Management), PP (Production Planning), CO (Controlling), and BASIS, cultivating a comprehensive understanding of how these modules interconnect to drive operational excellence.",
      image: "",
      conclusion:" My journey within the SAP ecosystem has been a captivating exploration of how technology transforms businesses across industries. With a strong foundation in ABAP development, I have had the privilege of contributing to projects that span an array of sectors, including pharmaceuticals, recycling, fabric, food production, metallurgy, paper and paperboard, plastic packaging, petroleum, gas pipes, and beyond.",
      skills: [
        {
          id: 1,
          title: "ABAP",
          percentage: 98,
          imageUrl: "/assets/abap.png",
        },
        {
          id: 2,
          title: "Fiori",
          percentage: 86,
          imageUrl: "/assets/fiori.png",
        },
        {
          id: 3,
          title: "SAPUI5",
          percentage: 89,
          imageUrl: "/assets/sapui5.svg",
        },
        {
          id: 4,
          title: "BASIS",
          percentage: 42,
          imageUrl: "/assets/basis.png",
        },
        {
          id: 5,
          title: "OData",
          percentage: 72,
          imageUrl: "/assets/odata.png",
        },
      ],
    },
    {
      id: 2,
      skillName: "Development",
      description:
        "I build complex web apps, from scratch until deployment, with multiple frameworks and architectures.",
      animation: "top",
      brief: "My journey in the realm of Full Stack Development has been an exciting exploration of technology's transformative power. With a core focus on the MEAN stack ( MongoDB, Express.js, Angular, and Node.js ). I have brought innovative ideas to life in projects spanning fintech applications, e-commerce platforms, digital signature services, and complex content management systems (CMS).",
      image: "",
      conclusion: "My experience as a Full Stack Developer has enabled me to merge my passion for both frontend and backend technologies, resulting in applications that seamlessly blend aesthetics with functionality. Furthermore, my expertise extends to cloud infrastructure, with proficiency in AWS, Heroku, and Azure. ",
      skills: [
        {
          id: 1,
          title: "Node JS",
          percentage: 92,
          imageUrl: "/assets/node.png",
        },
        {
          id: 2,
          title: "Angular",
          percentage: 90,
          imageUrl: "/assets/angular.png",
        },
        {
          id: 3,
          title: "React",
          percentage: 72,
          imageUrl: "/assets/react.png",
        },
        {
          id: 4,
          title: "MongoDB",
          percentage: 88,
          imageUrl: "/assets/mongodb.png",
        },
        {
          id: 5,
          title: "PostgreSQL",
          percentage: 83,
          imageUrl: "/assets/postgresql.png",
        },
        {
          id: 12,
          title: "AWS",
          percentage: 83,
          imageUrl: "/assets/aws.png",
        },
        {
          id: 13,
          title: "Gitlab",
          percentage: 65,
          imageUrl: "/assets/gitlab.png",
        },
        {
          id: 14,
          title: "Docker",
          percentage: 48,
          imageUrl: "/assets/docker.webp",
        },
        {
          id: 6,
          title: "Javascript",
          percentage: 92,
          imageUrl: "/assets/javascript.png",
        },
        {
          id: 11,
          title: "ASP .NET",
          percentage: 52,
          imageUrl: "/assets/asp.png",
        },
        {
          id: 7,
          title: "Wordpress",
          percentage: 82,
          imageUrl: "/assets/wordpress.png",
        },
        {
          id: 8,
          title: "Figma",
          percentage: 70,
          imageUrl: "/assets/figma.png",
        },
        {
          id: 9,
          title: "Swift",
          percentage: 35,
          imageUrl: "/assets/swift.svg",
        },
        {
          id: 10,
          title: "Android",
          percentage: 48,
          imageUrl: "/assets/android.png",
        },
      ],
    },
    {
      id: 3,
      skillName: "Tech Lead",
      description:
        "I design innovative applications with robust architectures, engaging with customers to discern requirements, and adeptly managing end-to-end project lifecycles.",
      animation: "right",
      brief: "As a Tech Lead I had to acomplish multiple tasks such as managing projects, teams, and acting as a pivotal link between clients and resources. My experience has been a dynamic exploration of how effective collaboration, efficient management, and streamlined processes drive the success of complex projects. Using modern tools, methodologies and process, I have orchestrated seamless project executions while fostering innovation and cohesion within cross-functional teams.",
      image: "",
      conclusion: "My experience extends beyond technical aspects. As a Tech Lead, I've fostered an environment that nurtures collaboration, innovation, and individual growth. I've mentored and guided teams to achieve their full potential, fostering a culture of excellence while ensuring a harmonious blend of client requirements and technical prowess.",
      skills: [
        {
          id: 1,
          title: "Project Lifecycle management",
          percentage: 'AGILE SCRUM',
          imageUrl: "/assets/tech1.png",
        },
        {
          id: 2,
          title: "Requirements analysis",
          percentage: 'Stakeholders end-users',
          imageUrl: "/assets/tech2.png",
        },
        {
          id: 3,
          title: "Architecture Design",
          percentage: 'Microservices Serverless',
          imageUrl: "/assets/tech3.png",
        },
        {
          id: 4,
          title: "Quality assurance",
          percentage: 'Code-reviews POC',
          imageUrl: "/assets/tech4.png",
        },
      ],
    },
  ];

  const [selectedSkill, setSelectedSkill] = useState(2);

  const targetRef = useRef(null);

  const handleSkillClick = (id) => {
    setSelectedSkill(id);
    /* if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    } */
  };

  return (
    <section className="skills-section">
      <div className="skills-container">
        {skills.map((skill) => (
          <SkillItem
            key={skill.id}
            skill={skill}
            isSelected={selectedSkill === skill.id}
            onClick={() => handleSkillClick(skill.id)}
          />
        ))}
      </div>
      {selectedSkill !== null && <TargetSection ref={targetRef} skills={skills} skill={selectedSkill} />}
    </section>
  );
};

const SkillItem = ({ skill, isSelected, onClick }) => {
  const [isVisible, setIsVisible] = useState(false);
  const subItemRef = useRef(null);
  const observerRef = useRef(null); // Create a mutable reference for the IntersectionObserver

  const handleIntersection = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      setIsVisible(true);
      observerRef.current.unobserve(entry.target);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Change this threshold value as needed
    };

    observerRef.current = new IntersectionObserver(handleIntersection, options);

    if (subItemRef.current) {
      observerRef.current.observe(subItemRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  return (
    <div
      ref={subItemRef}
      className={`skill-item ${skill.animation} ${isVisible ? "animate" : ""} ${
        isSelected ? "selected" : ""
      }`}
      onClick={onClick}
    >
      <SkillElement
        skillName={skill.skillName}
        description={skill.description}
        percentage={skill.percentage}
      />
    </div>
  );
};

const TargetSection = React.forwardRef((props, ref) => {
  const observerRef = useRef(null); 
  const handleIntersection = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      observerRef.current.unobserve(entry.target);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Change this threshold value as needed
    };

    observerRef.current = new IntersectionObserver(handleIntersection, options);

    if (ref.current) {
      observerRef.current.observe(ref.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  return (
    <section
      id="target-section"
      ref={ref}
      className={`skills animate`}
    >
      <p>{props?.skills?.find(item => item.id === props?.skill)?.brief}</p>
      <p>{props?.skills?.find(item => item.id === props?.skill)?.conclusion}</p>
      <SkillList skills={props?.skills?.find(item => item.id === props?.skill)?.skills}/>
    </section>
  );
});

const SkillList = ({skills}) => {
  return (
    <div className="skill-list">
      {skills.map((skill) => (
        <div key={skill.id} className="skill-list-item">
          <img src={skill.imageUrl} alt={skill.title} />
          <p><CustomColorText color="#cc00c5">{`${skill.percentage}${typeof skill.percentage === 'number' ? '%' : '' }`}</CustomColorText></p>
          <h3>{skill.title}</h3>
        </div>
      ))}
    </div>
  );
};

export default SkillsSection;
