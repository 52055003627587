import React from 'react';

const CustomColorText = ({ color, children }) => {
  const textStyle = {
    color: color,
  };

  return <span style={textStyle}>{children}</span>;
};

export default CustomColorText;