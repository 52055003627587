import React from "react";
import "./ProjectsSection.css";
import Carousel from "./Carousel";
import { v4 as uuidv4 } from "uuid";
import Card from "./Card";

const ProjectsSection = () => {
  let cards = [
    {
      key: uuidv4(),
      content: (
        <Card imagen='/assets/proy9.png' title='One Finance | Fintech App' link= 'https://www.one.app/' tag='Backend | Node'/>
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen='/assets/proy10.png' title='Fabric | Serverless E-Commerce' link= 'https://fabric.inc/' tag='Backend | Node'/>
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen='/assets/proy1.png' title= 'Tunland Ecuador | Landing Page' link='https://foton-tunland.com/home' tag='Frontend | Angular'/>
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen='/assets/proy2.png' title='Xipher | Digital Signure BaaS' link='https://xipher-landing.herokuapp.com/' tag='Backend | Node'/>
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen='/assets/proy3.png' title='Soy Martinez Borja | Landing page' link='https://ligaec.herokuapp.com/home' tag='FullStack | Angular'/>
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen='/assets/proy4.png' title='Aquaxcel | Web Site' link= 'https://aquaxcel.com/' tag='Frontend | Wordpress'/>
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen='/assets/proy5.png' title='CensoEcuador 2023 | Website' link= 'https://www.censoecuador.gob.ec/' tag='DevOps | Wordpress'/>
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen='/assets/proy6.png' title='Y Dale Aucas | Landing page' link= 'https://aucas.herokuapp.com/home' tag='FullStack | Angular'/>
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen='/assets/proy7.png' title='X-Core | E-Documents App' link= 'https://x-core-prd.herokuapp.com/sign-in' tag='FullStack | React'/>
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen='/assets/proy8.png' title='X-Shield | Privacy data platform' link= 'https://xshield-web.herokuapp.com' tag='Backend | Node'/>
      )
    },
  ];
  
  return (
    <section className="featured-projects-section">
      <Carousel
        cards={cards}
        height="60vh"
        minHeight="50vh"
        width="80%"
        margin="0 auto"
        offset={2}
        showArrows={false}
      />
      <a href="https://hex.ec/our-work" target="_blank" rel="noopener noreferrer" className="featured-button">MORE PROJECTS
      </a>
    </section>
  );
};

export default ProjectsSection;