import React, { useEffect, useRef, useState } from 'react';
import './TimelineSection.css';


const ProjectItem = ({ project, link }) => {
  return (
    <div className="sub-item-project">
      {link ? (
        <a href={link} target="_blank" rel="noreferrer">{project}</a>
      ) : (
        <p>{project}</p>
      )}
    </div>
  );
};

const SubItem = ({ position, projects}) => {

  const [isVisible, setIsVisible] = useState(false);
  const subItemRef = useRef(null);
  const observerRef = useRef(null); // Create a mutable reference for the IntersectionObserver

  const handleIntersection = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      setIsVisible(true);
      observerRef.current.unobserve(entry.target);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Change this threshold value as needed
    };

    observerRef.current = new IntersectionObserver(handleIntersection, options);

    if (subItemRef.current) {
      observerRef.current.observe(subItemRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  return (
    <div ref={subItemRef} className={`sub-item ${isVisible ? 'animate' : ''}`}>
      <h3>{position}</h3>
        { projects && projects.map((project, index) => (
          <ProjectItem
            key={index}
            project={project.name}
            link={project.link}
          />
        ))}
    </div>
  );
};

const TimelineItem = ({ active, company, link, yearFrom, yearTo, subitems }) => {
  return (
    <div className="timeline-item">
      <div className={`bullet ${active ? 'active' : ''}`} />
      <div className="timeline-company">
        {link ? (
          <a href={link} target="_blank" rel="noreferrer">{company}</a>
        ) : (
          <h1>{company}</h1>
        )}
      </div>
      <div className="timeline-item-years">
        {yearFrom} - {yearTo} 
      </div>
      <div className="timeline-item-info">
        {subitems && subitems.map((subitem, index) => (
          <SubItem
            key={index}
            position={subitem.position}
            projects={subitem.projects}
          />
        ))}
      </div>
      <div className="line" />
    </div>
  );
};

const TimelineSection = () => {
  // Replace with your actual work experience data
  const timelineData = [
    {
      id: 1,
      active: true,
      company: 'BAIRESDEV',
      link: 'https://www.bairesdev.com/',
      yearFrom: 2021,
      yearTo: 'Present',
      subitems: [
        {
          id: 1,
          position: 'BackEnd Developer',
          projects: [
            {
              name: 'One | Fintech App',
              link: 'https://www.one.app/',
            },
            {
              name: 'Fabric | Serverless E-Commerce',
              link: 'https://fabric.inc/',
            },
          ]
        },
      ],
    },
    {
      id: 2,
      active: false,
      company: 'HEX',
      link: 'https://hex.ec/',
      yearFrom: 2021,
      yearTo: 'Present',
      subitems: [
        {
          id: 1,
          position: 'CEO',
          projects: [
            {
              name: 'Developing high-quality business strategies and plans',
              link: '',
            }
          ]
        },
        {
          id: 2,
          position: 'Tech Lead',
          projects: [
            {
              name: 'XCore | E-Documents Platform',
              link: 'https://xcore.hex.ec/'
            },
            {
              name: 'XShield | Personal Privacy Data Protection Platform',
              link: 'https://xshield-web.herokuapp.com/'
            },
            {
              name: 'Aquaxcel | Landing page',
              link: 'https://aquaxcel.com/'
            },
            {
              name: 'Foret | Landing page',
              link: 'https://foret.ec/'
            },
            {
              name: 'Oikos | Landing page',
              link: 'https://familyoikos.com/'
            },
          ]
        },
        {
          id: 3,
          position: 'Full Stack Developer',
          projects: [
            {
              name: 'Xipher | E-documents BaaS',
              link: 'https://www.xipher.app/'
            },
            {
              name: 'Leader | Lead management platform',
              link: 'https://leader.hex.ec/'
            },
            {
              name: 'Tunland | Landing page',
              link: 'https://foton-tunland.com/home'
            },
            {
              name: 'Soy Martinez Borja | Futbol club event',
              link: 'https://ligaec.herokuapp.com/home'
            },
            {
              name: 'Y Dale Aucas | Futbol club event',
              link: 'https://aucas.herokuapp.com/home'
            },
            {
              name: 'Skyrizi | Dermatologic valuation app',
              link: 'https://skyrizi.herokuapp.com/login'
            },
            {
              name: 'Dulcesmomentos | Brand Launch Streaming app',
              link: 'https://dulcesmomentos.herokuapp.com/home'
            },
            {
              name: 'Exportquilsa | Roulette game app',
              link: 'https://exportquilsa.herokuapp.com/home'
            },
            {
              name: 'Cargil | Card game app',
              link: 'https://aquaxcel.herokuapp.com/home'
            },
          ]
        },
        {
          id: 4,
          position: 'Web Developer',
          projects: [
            {
              name: 'Foton | Landing page',
              link: 'https://fotonecuador.com/'
            },
            {
              name: 'Coheco | Landing page',
              link: 'https://gws.hex.ec/'
            },
            {
              name: 'Tetrarecart | Landing page',
              link: 'https://gustadina.com.ec/nuevosempaques/que-son/'
            },
          ]
        },
      ],
    },
    {
      id: 3,
      active: false,
      company: 'XEON',
      link: 'https://xeon.ec/',
      yearFrom: 2020,
      yearTo: 'Present',
      subitems: [
        {
          id: 1,
          position: 'CO FOUNDER',
          projects: [
            {
              name: 'Business Planning and Identifying Market Opportunities',
              link: ''
            },
          ]
        },
        {
          id: 2,
          position: 'BackEnd Developer',
          projects: [
            {
              name: 'Integra | Cotel Purchase Orders software',
              link: 'https://cotel.com.co/'
            },
            {
              name: 'Camenet | Cartones América Sales and quotation solftware',
              link: ''
            },
            {
              name: 'Vale | medic appointments app',
              link: ''
            },
          ]
        },
        {
          id: 3,
          position: 'Full Stack Developer',
          projects: [
            {
              name: 'GoldenGarbage | Waste Recycle app',
              link: 'http://www.thegoldengarbage.com/'
            },
            {
              name: 'Inspire Travellers | Social Turistic app',
              link: ''
            },
            {
              name: 'One | Brand Building purchase orders software',
              link: ''
            },
            {
              name: 'Passenger | shared transport app',
              link: ''
            },
          ]
        },
        {
          id: 3,
          position: 'Senior SAP ABAP Developer',
          projects: [
            {
              name: 'Cartones América | IT Service Desk support ',
              link: ''
            },
          ]
        },
        {
          id: 3,
          position: 'FrontEnd Developer',
          projects: [
            {
              name: 'Actib Health | Health program app',
              link: 'https://www.actibhealth.com/'
            },
          ]
        },
      ],
    },
    {
      id: 4,
      active: false,
      company: 'SONDA',
      link:'https://www.sonda.com/',
      yearFrom: 2019,
      yearTo: 2021,
      subitems: [
        {
          id: 1,
          position: 'Senior SAP ABAP Developer',
          projects: [
            {
              name: 'Muy Mucho | SAP S4 HANA Implementation',
              link: ''
            },
            {
              name: 'Moderna Alimentos | SAP S4 HANA Migration',
              link: 'https://modernaalimentos.com.ec/'
            },
            {
              name: 'Grupo Sigmaplast | SAP S4 HANA Implementation',
              link: 'https://www.sigmaplast.com/'
            },
            {
              name: "L'Oreal | Ecuador Fiscal requirements SAP Implementation",
              link: ''
            },
          ]
        },
      ],
    },
    {
      id: 5,
      active: false,
      company: 'IT & APPS CONSULTORES',
      yearFrom: 2018,
      yearTo: 2019,
      subitems: [
        {
          id: 1,
          position: 'Senior SAP ABAP Developer',
          projects: [
            {
              name: 'Cartones América | SAP Service Desk support',
              link: 'https://www.cartonesamerica.com/'
            },
            {
              name: 'Cartones América | SAP ERP Upgrade to ECC 6.7',
              link: ''
            },
          ]
        },
      ],
    },
    {
      id: 6,
      active: false,
      company: 'FREELANCE',
      yearFrom: 2018,
      yearTo: 2018,
      subitems: [
        {
          id: 1,
          position: 'Frelance Senior SAP ABAP Developer',
          projects: [
            {
              name: 'Vendomática | Ecuador Fiscal requirements SAP Implementation',
              link: 'https://www.vendomatica.pe/'
            },
            {
              name: 'Visit Cuenca | Turistic Movil App',
              link: 'https://play.google.com/store/apps/details?id=com.cuenca&hl=es_EC&gl=US'
            },
            {
              name: 'SanLucar Fruit | Ecuador Fiscal requirements SAP Implementation',
              link: 'https://www.sanlucar.com/es/'
            },
          ]
        },
      ],
    },
    {
      id: 7,
      active: false,
      company: 'REDIGITAL',
      yearFrom: 2017,
      yearTo: 2018,
      subitems: [
        {
          id: 1,
          position: 'Tech Lead',
          projects: [
            {
              name: 'QuitoTeConecta | Turistic Movil App',
              link: ''
            },
            {
              name: 'GoUIO | Turistic Movil App',
              link: ''
            },
            {
              name: 'La Conversación | Landing Page',
              link: 'https://dialoguemos.ec/'
            },
          ]
        },
      ],
    },
    {
      id: 8,
      active: false,
      company: 'CHAIDE',
      link: 'https://www.chaide.com/',
      yearFrom: 2017,
      yearTo: 2017,
      subitems: [
        {
          id: 1,
          position: 'IT Analyst',
          projects: [
            {
              name: 'IT HelpDesk Support and SAP Development',
              link: ''
            },
          ]
        },
      ],
    },
    {
      id: 9,
      active: false,
      company: 'SONDA',
      link:'https://www.sonda.com/',
      yearFrom: 2014,
      yearTo: 2017,
      subitems: [
        {
          id: 1,
          position: 'SAP ABAP Developer',
          projects: [
            {
              name: 'Contecon | SAP Service Desk support',
              link: 'https://www.cgsa.com.ec/'
            },
            {
              name: 'Chaide | SAP Service Desk support',
              link: 'https://www.chaide.com/'
            },
            {
              name: 'Ingenio San Carlos | SAP Service Desk support',
              link: 'https://www.sancarlos.com.ec/'
            },
            {
              name: 'Grupasa | SAP ERP Upgrade to ECC 6.7',
              link: 'https://grupasa.com/'
            },
            {
              name: 'Pfizer | Ecuador Fiscal requirements SAP Implementation',
              link: 'https://www.pfizer.com.ec/'
            },
            {
              name: 'El Rosado | Ecuador Fiscal requirements SAP Implementation',
              link: 'https://www.elrosado.com/'
            },
            {
              name: 'Enkador | SAP ERP Implementation',
              link: 'https://www.enkador.com/'
            },
            {
              name: 'Grupo Familia | SAP Service Desk support',
              link: 'https://www.grupofamilia.com/'
            },
            {
              name: 'Tenaris Global | Ecuador Fiscal requirements SAP Implementation',
              link: 'https://www.tenaris.com/es'
            },
            {
              name: 'Moderna Alimentos | Ecuador Fiscal requirements SAP Implementation',
              link: 'https://modernaalimentos.com.ec/'
            },
            {
              name: 'Cartones América | Ecuador Fiscal requirements SAP Implementation',
              link: 'https://www.cartonesamerica.com/'
            },
            {
              name: 'Tecpetrol | Ecuador Fiscal requirements SAP Implementation',
              link: 'https://www.tecpetrol.com/es'
            },
            {
              name: 'Roche | Ecuador Fiscal requirements SAP Implementation',
              link: 'https://www.roche.com.ec/'
            },
            {
              name: 'Promesa | Ecuador Fiscal requirements SAP Implementation',
              link: 'https://www.promesa.com.ec/'
            },
          ]
        },
      ],
    },
    {
      id: 10,
      active: false,
      company: 'BOC ADVISORS',
      yearFrom: 2013,
      yearTo: 2014,
      subitems: [
        {
          id: 1,
          position: 'SAP ABAP Developer',
          projects: [
            {
              name: 'Chaide | SAP Service Desk support',
              link: 'https://www.chaide.com/'
            },
            {
              name: 'Arca Continental | SAP Service Desk support',
              link: 'https://www.arcacontal.com/'
            },
          ]
        },
      ],
    },
    {
      id: 11,
      active: false,
      company: 'UNIVERSIDAD DE LAS AMÉRICAS',
      link: 'https://www.udla.edu.ec/',
      yearFrom: 2012,
      yearTo: 2015,
      subitems: [
        {
          id: 1,
          position: 'Networking and Communications Electronic Engeniering',
          projects: [
            {
              name: "Bachelor's Degree",
              link: ''
            },
          ]
        },
      ],
    },
    {
      id: 12,
      active: false,
      company: 'UNIVERSIDAD DE LAS FUERZAS ARMADAS',
      link: 'https://www.espe.edu.ec/',
      yearFrom: 2007,
      yearTo: 2012,
      subitems: [
        {
          id: 1,
          position: 'Mechatronic engineering',
          projects: [
            {
              name: '8th Semester',
              link: ''
            },
          ]
        },
      ],
    },
  ];

  return (
    <section className="timeline-section">
      {timelineData.map((item) => (
        <TimelineItem
          key={item.id}
          active={item.active}
          yearFrom={item.yearFrom}
          yearTo={item.yearTo}
          company={item.company}
          link={item.link}
          subitems={item.subitems}
        />
      ))}
    </section>
  );
};

export default TimelineSection;
